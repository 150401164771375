


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';
import {TEventSettings} from '@/_types/event-settings.type';
import QrcodeVue from 'qrcode.vue';
import {Route} from 'vue-router';

type TGetTicketsButtonAttributes = {
  href: string;
  target: string;
  buttonText?: string;
}

type TShowAuthPopupParams = {
  isAuthPopupVisible: boolean;
  targetRoute: Route;
}

@Component({
  components: {
    QrcodeVue,
  }
})
export default class SectionHero extends Vue {

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isLoading') isAuthLoading: boolean;

  public get heroSectionStyles(): {[key: string]: string} {
    const result: {[key: string]: string} = {};
    if (this.heroBackgroundImageUrl) {
      result.backgroundImage = 'url(' + this.heroBackgroundImageUrl + ')';
    }
    return result;
  }

  public get heroBackgroundImageUrl(): string {
    return (this.event && this.event.image) || '';
  }

  public get eventTypeName(): string {
    return (this.event && this.event.event_type && this.event.event_type.name) || '';
  }

  public get eventTitle(): string {
    return (this.event && this.event.title) || '';
  }

  public get eventTitleClasses(): {[key: string]: boolean} {
    const titleLength = this.eventTitle.length;
    return {
      'title-short': titleLength <= 30,
      'title-long': (titleLength > 30) && (titleLength <= 60),
      'title-very-long': titleLength > 60
    };
  }

  // TODO: does this make sense? dateStart milliseconds is always less than dateEnd milliseconds
  public isShowingEndDateNeeded(): boolean {
    return this.eventDateStart.getTime() < this.eventDateEnd.getTime();
  }

  public get eventDateStart(): Date {
    return (this.event && this.event.date_start) || new Date();
  }

  public get eventDateEnd(): Date {
    return (this.event && this.event.date_end) || new Date();
  }

  public get eventOrganizerLogoUrl(): string {
    return (this.event && this.event.org_logo) || '';
  }

  public get qrCodeUrl(): string {
    return window.location.href;
  }

  public get getTicketsButtonAttributes(): TGetTicketsButtonAttributes {
    if (!this.event) {
      return {
        href: null,
        target: null,
      };
    }

    const result: TGetTicketsButtonAttributes = {
      href: null,
      target: null,
      buttonText: '',
    };

    if (this.event.buy_ticket_url) {
      result.href = this.event.buy_ticket_url;
      result.target = '_blank';
      result.buttonText = this.$t('button.buyTickets') as string;
    }

    return result;
  }

  public get hasPersonalAccess(): boolean {
    return !!(this.event && this.event.personal && this.event.personal.has_access);
  }

  public get isQuickRegisterButtonVisible(): boolean {
    return !!this.getTicketsButtonAttributes.href;
  }

  public async showAuthPopup(payload?: TShowAuthPopupParams): Promise<void> {
    const defaultPayload: TShowAuthPopupParams = {
      isAuthPopupVisible: true,
      targetRoute: null,
    };
    const { /* isAuthPopupVisible, */ targetRoute } = (payload || defaultPayload);
    // TODO: remove line, older design remnants
    // await this.$store.dispatch('authStore/setAuthPopupVisible', { isAuthPopupVisible, targetRoute });
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', targetRoute);
    this.$store.dispatch('authStore/setStandaloneAuthVisibility', true);
  }

}
